<template>
  <div class="container-fluid pt-3">
    <div class="card mt-5" style="max-width: 800px; margin: 0 auto !important">
      <div class="card-body">
        <Filtros
          :title="`Relatório Pagamentos x Lucratividade`"
          @filtrar="setFiltros"
          :opts="{
            empresas: {
              visible: false
            },
            users: {
              visible: false
            },
            pacientes: {
              visible: false
            }
          }"
        >
          <div class="col-12 col-sm-4 pt-2 text-left pl-0">
            <b-form-group label="Tipo">
              <b-select :options="['Analítico', 'Resumido']" v-model="tipo" />
            </b-form-group>
          </div>
        </Filtros>
      </div>
    </div>
    <hr />
    <div v-if="exibir" style="max-width:100%; margin:0 auto;">
      <div class="text-center p-4">
        <b-btn variant="info" @click="imp2"><b-icon-printer /> Imprimir</b-btn>
      </div>
      <div id="printDiv">
        <FaturasComLucratividade
          :filtros="filtros"
          v-if="exibir"
          :show="show"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Filtros from "../../../../components/Relatorios/Filtros.vue";
import FaturasComLucratividade from "../../../../components/Relatorios/Financeiro/ContasPagar/FaturasComLucratividade.vue";
import PrinterWindowHelper from "../../../../helpers/PrinterWindowHelper";
export default {
  components: {
    Filtros,
    FaturasComLucratividade
  },
  props: {},
  mounted() {},
  data() {
    return {
      filtros: {},
      tipo: "Analítico",
      exibir: false,
      show: true
    };
  },
  watch: {
    filtros: {
      deep: true,
      handler() {
        this.exibir = false;
      }
    }
  },
  computed: {},
  methods: {
    imp() {
      this.show = false;
      this.exibir = false;
      this.$nextTick();
      setTimeout(() => {
        this.exibir = true;
      }, 300);
    },
    imp2() {
      PrinterWindowHelper(document.getElementById("printDiv").innerHTML);
    },
    setFiltros(filtros) {
      this.filtros = filtros;
      this.filtros.tipo = this.tipo;
      this.exibir = false;
      this.$nextTick();
      setTimeout(() => {
        this.exibir = true;
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>