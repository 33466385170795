var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid pt-3"},[_c('div',{staticClass:"card mt-5",staticStyle:{"max-width":"800px","margin":"0 auto !important"}},[_c('div',{staticClass:"card-body"},[_c('Filtros',{attrs:{"title":`Relatório Pagamentos x Lucratividade`,"opts":{
          empresas: {
            visible: false
          },
          users: {
            visible: false
          },
          pacientes: {
            visible: false
          }
        }},on:{"filtrar":_vm.setFiltros}},[_c('div',{staticClass:"col-12 col-sm-4 pt-2 text-left pl-0"},[_c('b-form-group',{attrs:{"label":"Tipo"}},[_c('b-select',{attrs:{"options":['Analítico', 'Resumido']},model:{value:(_vm.tipo),callback:function ($$v) {_vm.tipo=$$v},expression:"tipo"}})],1)],1)])],1)]),_c('hr'),(_vm.exibir)?_c('div',{staticStyle:{"max-width":"100%","margin":"0 auto"}},[_c('div',{staticClass:"text-center p-4"},[_c('b-btn',{attrs:{"variant":"info"},on:{"click":_vm.imp2}},[_c('b-icon-printer'),_vm._v(" Imprimir")],1)],1),_c('div',{attrs:{"id":"printDiv"}},[(_vm.exibir)?_c('FaturasComLucratividade',{attrs:{"filtros":_vm.filtros,"show":_vm.show}}):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }