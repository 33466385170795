<template>
  <div>
    <div v-if="loading" class="text-center p-4">
      <b-spinner />
    </div>
    <print-component
      v-if="printNow"
      :printNow="printNow"
      :show="show"
      @done="donePrint"
      :titulo="`Pagamentos X Lucratividade`"
      :filtros="filtro"
    >
      <section class="pdf-content">
        <table
          v-if="!filtros.tipo || (filtros.tipo && filtros.tipo == 'Analítico')"
          class="table table-sm table-bordered mt-1  table-med-bordered text-center"
          style="font-size:11px; margin:0 auto"
        >
          <thead>
            <tr class="bg-light">
              <th rowspan="2" class="text-center" style="vertical-align:middle">
                Especialidade / Médico / Consulta
              </th>
              <th colspan="5">Quantidade x Custo</th>
              <th rowspan="2">Venda</th>
              <th rowspan="2">% Lucro <small>(s/imposto)</small></th>
            </tr>
            <tr class="bg-light">
              <th>Cons.</th>
              <th>Acert.</th>
              <th>Acrt. + Cons.</th>
              <th>Valor Un.</th>
              <th>Total Custo</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="espec in dados">
              <tr
                :key="`lin_key_${espec.especialidade}_${Math.random()}2`"
                style="background-color: #ccc !important"
              >
                <td
                  colspan="9999"
                  style="background-color: #ccc !important"
                ></td>
              </tr>
              <tr
                :key="`lin_key_${espec.especialidade}_${Math.random()}`"
                class="bg-light2"
              >
                <td class="text-left bg-light2">
                  <b>{{ espec.especialidade }}</b>
                </td>
                <td class="bg-light2">
                  {{ espec.resumos.qtd_consultas || "" }}
                </td>
                <td class="bg-light2">
                  {{ espec.resumos.qtd_acertos || "" }}
                </td>
                <td class="bg-light2">
                  {{
                    espec.resumos.qtd_consultas + espec.resumos.qtd_acertos ||
                      ""
                  }}
                </td>
                <td class="bg-light2"></td>
                <td class="bg-light2">
                  {{
                    (espec.resumos.vl_custo +
                      espec.medicos.reduce((ret, vl) => {
                        ret += (vl.vl_acrescimo - vl.vl_desconto) - (vl.vl_devolucoes || 0) ;
                        return ret;
                      }, 0))
                      | currency
                  }}
                </td>
                <td class="bg-light2">
                  {{ espec.resumos.vl_venda | currency }}
                </td>
                <td class="bg-light2">
                  <b>
                    {{
                      (espec.resumos.vl_venda == 0
                        ? 0
                        : (espec.resumos.vl_venda /
                            (espec.resumos.vl_custo +
                              espec.medicos.reduce((ret, vl) => {
                                ret += vl.vl_acrescimo - vl.vl_desconto - (vl.vl_devolucoes || 0);
                                return ret;
                              }, 0)) -
                            1) *
                          100) | currency
                    }}
                    %</b
                  >
                </td>
              </tr>
              <template v-for="medic in espec.medicos">
                <tr
                  :key="`tr_lin_${medic.medico}_${Math.random(2323223222)}3`"
                  style="background-color: #dedede !important"
                >
                  <td
                    colspan="9999"
                    style="background-color: #dedede !important"
                  ></td>
                </tr>
                <tr
                  style="font-size:9px"
                  :key="`tr_lin_${medic.medico}_${Math.random(23232232)}`"
                  class="bg-light3"
                >
                  <td class="pl-4 text-left">
                    <b>{{ medic.medico }}</b>
                    <small v-if="medic.referente" style="font-style:italic">
                      <br />
                      {{ medic.referente }}
                    </small>
                  </td>
                  <td>
                    {{ medic.resumos.qtd_consultas || "" }}
                  </td>
                  <td>
                    {{ medic.resumos.qtd_acertos || "" }}
                  </td>
                  <td>
                    {{
                      medic.resumos.qtd_acertos + medic.resumos.qtd_consultas ||
                        ""
                    }}
                  </td>
                  <td></td>
                  <td>
                    {{ medic.resumos.vl_custo | currency }}
                    <span
                      v-if="medic.vl_desconto > 0 || medic.vl_acrescimo > 0 || medic.vl_devolucoes > 0"
                    >
                      <span v-if="medic.vl_acrescimo - medic.vl_desconto > 0">
                        +
                      </span>
                      {{ (medic.vl_acrescimo - medic.vl_desconto - medic.vl_devolucoes) | currency }}
                      =
                      {{
                        (medic.resumos.vl_custo +
                          (medic.vl_acrescimo - medic.vl_desconto - medic.vl_devolucoes))
                          | currency
                      }}
                    </span>
                  </td>
                  <td>{{ medic.resumos.vl_venda | currency }}</td>
                  <td>
                    <b
                      >{{
                        (medic.resumos.vl_venda == 0
                          ? 0
                          : (medic.resumos.vl_venda /
                              (medic.resumos.vl_custo +
                                (medic.vl_acrescimo - medic.vl_desconto  - medic.vl_devolucoes)) -
                              1) *
                            100) | currency
                      }}%</b
                    >
                  </td>
                </tr>

                <tr
                  v-if="medic.vl_desconto > 0 || medic.vl_acrescimo > 0 || medic.vl_devolucoes>0"
                  :key="`tr_lin23_${medic.medico}_${Math.random(23232232)}`"
                  style="font-size:8px"
                  class="bg-light"
                >
                  <td class="text-left pl-5 ">
                    <div v-if="medic.desconto_itens && medic.desconto_itens.length>0">
                      <b>Descontos:</b>
                      <div style="font-size:7px" v-for="desc in medic.desconto_itens" :key="`desc_${desc.id}`">
                        {{ desc.descricao }} ({{ desc.venda_data | moment("DD/MM/YYYY")  }}): {{ desc.vl_custo | currency }}
                      </div>
                    </div>
                    <b>{{
                      medic.obs && medic.obs != ""
                        ? medic.obs
                        : "-- obs não informada --"
                    }}</b>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {{ (medic.vl_acrescimo - medic.vl_desconto - medic.vl_devolucoes) | currency }}
                  </td>
                  <td></td>
                  <td></td>
                </tr>

                <template v-for="ex in medic.exames">
                  <tr
                    :key="`link_ex_${ex.exame}_${Math.random(2323232)}`"
                    style="font-size:8px"
                  >
                    <td class="text-left pl-5  ">
                      {{ ex.exame }}
                    </td>
                    <td>
                      {{ ex.resumos.qtd_consultas || "" }}
                    </td>
                    <td>
                      {{ ex.resumos.qtd_acertos || "" }}
                    </td>
                    <td>
                      {{
                        ex.resumos.qtd_consultas + ex.resumos.qtd_acertos || ""
                      }}
                    </td>
                    <td>
                      {{ ex.vl_custo | currency }}
                    </td>
                    <td>
                      {{ ex.resumos.vl_custo | currency }}
                    </td>

                    <td>
                      {{ ex.resumos.vl_venda | currency }}
                    </td>

                    <td>
                      <b
                        >{{
                          (ex.resumos.vl_venda == 0
                            ? 0
                            : (ex.resumos.vl_venda / ex.resumos.vl_custo - 1) *
                              100) | currency
                        }}%</b
                      >
                    </td>
                  </tr>
                </template>
              </template>
            </template>
            <tr
              class="bg-secondary text-white text-font-weight"
              style="background-color: red !important "
            >
              <th style="background-color: red !important ">
                TOTAL
              </th>
              <th style="background-color: red !important ">
                {{
                  dados.reduce((ret, vl) => {
                    ret += vl.resumos.qtd_consultas;
                    return ret;
                  }, 0)
                }}
              </th>
              <th style="background-color: red !important ">
                {{
                  dados.reduce((ret, vl) => {
                    ret += vl.resumos.qtd_acertos;
                    return ret;
                  }, 0)
                }}
              </th>
              <th style="background-color: red !important ">
                {{
                  dados.reduce((ret, vl) => {
                    ret += vl.resumos.qtd_acertos + vl.resumos.qtd_consultas;
                    return ret;
                  }, 0)
                }}
              </th>
              <th style="background-color: red !important "></th>
              <th style="background-color: red !important ">
                {{
                  dados.reduce((ret, vl) => {
                    ret +=
                      vl.resumos.vl_custo +
                      vl.medicos.reduce((t, v) => {
                        t += v.vl_acrescimo - v.vl_desconto - v.vl_devolucoes;
                        return t;
                      }, 0);
                    return ret;
                  }, 0) | currency
                }}
              </th>
              <th style="background-color: red !important ">
                {{
                  dados.reduce((ret, vl) => {
                    ret += vl.resumos.vl_venda;
                    return ret;
                  }, 0) | currency
                }}
              </th>
              <th style="background-color: red !important ">
                {{
                  ((dados.reduce((ret, vl) => {
                    ret += vl.resumos.vl_venda;
                    return ret;
                  }, 0) /
                    dados.reduce((ret, vl) => {
                      ret +=
                        vl.resumos.vl_custo +
                        vl.medicos.reduce((t, v) => {
                          t += v.vl_acrescimo - v.vl_desconto - v.vl_devolucoes;
                          return t;
                        }, 0);
                      return ret;
                    }, 0) -
                    1) *
                    100)
                    | currency
                }}
                %
              </th>
            </tr>
          </tbody>
        </table>
        <!------------------------ RELATORIO RESUMIDO ------------------>
        <table
          v-if="filtros && filtros.tipo && filtros.tipo === 'Resumido'"
          class="table table-sm "
          style="font-size:9px"
        >
          <thead>
            <tr>
              <th class="text-left">Médico</th>
              <th class="text-left">Especialidade</th>
              <th class="text-center">Valor</th>
              <th>Dados Pagamento</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="res in resumido">
              <tr  :key="`res_${res.especialidade}_${Math.random()}`" class="bg-light text-left" style="background-color:#dedede; font-size:13px">
                <th>
                  {{res.especialidade}}
                </th>
                <th></th>
                <th></th>
                <th class="text-right">
                  Total: 
                  {{
                    res.medicos.reduce((ret,vl)=>{ ret += vl.resumos.vl_custo + (vl.vl_acrescimo - vl.vl_desconto - vl.vl_devolucoes);return ret;},0)
                    | currency
                  }}
                </th>
              </tr>
              <tr
                v-for="med in res.medicos"
                :key="`lin_med_pgt_${med.medico}_${Math.random()}`"
              >
                <td class="text-left">
                  {{ med.medico }}
                </td>
                <td class="text-left">
                  {{ med.especialidade }}
                </td>
                <td class="text-right">
                  <b>{{
                    (med.resumos.vl_custo + (med.vl_acrescimo - med.vl_desconto - med.vl_devolucoes))
                      | currency
                  }}</b>
                </td>
                <td
                  class="text-left"
                  style="border-left:1px solid #999; padding-left:10px"
                >
                  <span v-if="med.forma_pagamento">
                    <b>Tipo:</b>
                    {{
                      med.forma_pagamento && med.forma_pagamento.tipo
                        ? med.forma_pagamento.tipo
                        : ""
                    }}
                    |
                    <span v-if="med.forma_pagamento.banco">
                      <b>Banco: </b> {{ med.forma_pagamento.banco }} |
                    </span>
                    <span v-if="med.forma_pagamento.tipo_chave">
                      <b>Tipo Chave: </b> {{ med.forma_pagamento.tipo_chave }} |
                    </span>

                    <span v-if="med.forma_pagamento.agencia">
                      <b>AG.: </b> {{ med.forma_pagamento.agencia }}
                    </span>
                    <span v-if="med.forma_pagamento.numero">
                      <b>Num.: </b> {{ med.forma_pagamento.numero }}
                    </span>
                    <span v-if="med.forma_pagamento.chave">
                      <b>Num.: </b> {{ med.forma_pagamento.chave }}
                    </span>
                    <span v-if="med.forma_pagamento.digito">
                      -{{ med.forma_pagamento.digito }} |
                    </span>
                    <span v-if="med.medicoCPF"
                      ><br />
                      <b>CPF: </b> {{ med.medicoCPF }}
                    </span>
                  </span>
                </td>
              </tr>
            </template>
            <tr style="font-size:18px; background-color: #bbb">
              <th style="font-size:18px; background-color: #bbb">TOTAL</th>
              <th style="font-size:18px; background-color: #bbb"></th>
              <th style="font-size:18px; background-color: #bbb">
                
              </th>
              <th style="font-size:18px; background-color: #bbb">
                {{
                    resumido.reduce((r,v)=>{ r+= v.medicos.reduce((ret,vl)=>{ ret += vl.resumos.vl_custo + (vl.vl_acrescimo - vl.vl_desconto - vl.vl_devolucoes);return ret;},0); return r;},0)
                    | currency
                  }}
              </th>
            </tr>
          </tbody>
        </table>
      </section>
    </print-component>
  </div>
</template>

<script>
import moment from "moment";
import RelatoriosLib from "../../../../libs/RelatoriosLib";
import PrintComponent from "../../../Print/PrintComponent.vue";

export default {
  components: {
    PrintComponent
  },
  props: {
    filtros: Object,
    show: { type: Boolean, default: false }
  },
  mounted() {
    this.formataFiltro();
    this.carregar();
  },
  data() {
    return {
      loading: true,
      printNow: false,
      dados: [],
      filtro: "",
      resumido: []
    };
  },
  watch: {
    filtros: {
      deep: true,
      handler() {
        // this.formataFiltro();
        // this.carregar();
      }
    }
  },
  computed: {},
  methods: {
    formataFiltro() {
      this.filtro = `<b>Periodo: </b> ${moment(
        this.filtros.periodo.dt_i
      ).format("DD/MM/YYYY")} a ${moment(this.filtros.periodo.dt_f).format(
        "DD/MM/YYYY"
      )} ${
        this.filtros.medicos && this.filtros.medicos.length > 0
          ? " - <b>Medicos:</b> " +
            this.filtros.medicos.map(i => i.razao_social).join(" | ")
          : ""
      }`;
    },
    async carregar() {
      this.loading = true;
      this.printNow = false;
      try {
        let res = await RelatoriosLib.RelatorioPagamentosLucratividade(
          this.filtros
        );
        console.log("res", res);
        if (res && res.success) {
          if (
            this.filtros &&
            this.filtros.tipo &&
            this.filtros.tipo === "Resumido"
          ) {
            this.resumido = res.data
              .sort((a, b) => {
                return a.especialidade > b.especialidade ? 1 : -1;
              })
              .sort((a, b) => {
                return a.especialidade.toUpperCase().indexOf("OFTALMO") >= 0 ||
                  b.especialidade.toUpperCase().indexOf("OFTALMO") >= 0
                  ? -1
                  : 1;
              });
              // .reduce((ret, vl) => {
              //   ret.push(...vl.medicos);
              //   return ret;
              // }, []);
            console.log("data", this.resumido);
          } else {
            this.dados = res.data
              .sort((a, b) => {
                return a.especialidade > b.especialidade ? 1 : -1;
              })
              .sort((a, b) => {
                return a.especialidade.toUpperCase().indexOf("OFTALMO") >= 0 ||
                  b.especialidade.toUpperCase().indexOf("OFTALMO") >= 0
                  ? -1
                  : 1;
              });
          }
          this.printNow = true;
        }
      } catch (err) {
        console.log("Erro ao buscar relat", err);
      }
      this.loading = false;
    },
    donePrint() {
      this.show = true;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>